<template>
  <div class="d-flex py-4 pl-10 pr-7 align-center elevation-2 rounded" :class="{ detail: true, mobile: isMobile }" style="position: relative;">

    <Avatar :size="70" :did="messageRecord.friendAddress" :hash="messageRecord.receiverPhoto"
	 :grade="messageRecord.grade"
	 imgType="bigv"
	 showAvatarInfo />
    <div class="d-flex flex-column ml-4" style="width: 70%;" :class="{star:messageRecord.wordsStatus==2}">
      <span class="text-h6" style="font-weight:bold !important;">{{ messageRecord.receiverName }}</span>
      <span class="subtitle-2" style="font-size: 11px !important; ">{{ messageRecord.friendAddress }}</span>
      <span class="content" style="font-size: 14px !important;" v-html="messageRecord.content"></span>
    </div>
    <v-spacer></v-spacer>
    <div class="msg-time" style="font-size: 14px; width: 145px; min-width: 145px;">{{ sendMsgTime }}</div>
    <img class="msg ml-11" width="24px" height="24px"
            src="@/assets/icons/btn_message.png" alt="" @click="getOpen" />
  </div>
  
</template>

<script lang='js'>
import Avatar from "@/components/avatar/index.vue";

export default {
  name: 'MessageItem',
  components:{Avatar},
  props: {
      messageRecord: Object
  },
  data: function() {
    return {
      sendMsgTime:'',
    };
  },
    computed: {
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    mounted() {
        console.log('333333',this.messageRecord);
        this.messageRecord.content = this.messageRecord.content.replace(/\n/g, "<br/>");
    },
  created(){
    let that = this;
    let time = this.messageRecord.sendTime;//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    function timestampToTime() {
      let date = new Date(time);
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      let D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
      let h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
      let s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
      let strDate = Y+M+D+h+m+s;
      return that.sendMsgTime = strDate;
    }
    timestampToTime()
  },
  methods: {
	  getOpen() {
		  this.$emit("getOpen", this.messageRecord);
	  }
  }
};

</script>

<style lang='scss' scoped>
    .elevation-2 {
        box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 9%) !important;
        margin-top: 9px;
    }
    
    .star::before {
        content: ".";
        font-size: 60px;
        color: red;
        position: absolute;
        left: 25px;
        top: -30px;
        font-family: Comic Sans MS;
    }
    
	.mobile {
		padding: 20px !important;
		&::v-deep.elevation-2 {
			padding-bottom: 80px !important;
		}
		
		.text-h6 {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-bottom: 25px;
		}
		
		.subtitle-2 {
			position: absolute;
			left: 105px;
			top: 60px;
			width: 45%;
			font-family: "\5FAE\8F6F\96C5\9ED1";
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.content {
			position: absolute;
			left: 20px;
			top: 110px;
			width: 90%;
			
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		
		.msg-time {
			position: absolute;
			left: 20px;
			top: 135px;
		}
		.msg {
			margin-left: 10px !important;
		}
        
        .star::before {
            left: 10px;
            top: -30px;
        }
        
	}
	
</style>
