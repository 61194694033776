<template>
   <div class="overflow-y-auto" @scroll="onScroll">
      <MessageItem
          class="mb-3"
          v-for="(item, i) in list"
          :key="i"
          :messageRecord="item"
          v-scroll="onScroll"
		  @getOpen="getOpen"
      />
         <div v-if="list.length == 0 && !isShowLoading" class="empty">{{ $t("EmptyHere") }}</div>
         <loading :isShowLoading="isShowLoading" ></loading>
    <div v-if="open" style="position: fixed; right: 20px; bottom: 20px; z-index: 99;">
      <message ref="message" @getOpen="Open" v-if="open" :currfriend="currfriend"></message>
    </div>
   </div>
</template>

<script lang='js'>
	import message from "@/components/popup/message.vue";
  import MessageItem from "./components/MessageItem.vue";
  import Loading from "@/components/loading.vue";
  import api from "@/api";

  export default {
    name: "UserMessage",
    components: { message,MessageItem,Loading },
    props: {},
    data: function() {
      return {
        list: [],
        pageNumber: 1,
        pageSize: 7,
        totalPage: 0,
		open: false,
    currfriend: { },
     isShowLoading: false,
      };
    },
    methods: {
      async getMessage() {
        this.isShowLoading = true
        let params = {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
        };
        let res = await api.home.listRecord(params);
        console.log("wxl ----- listRecord")
        console.log(res)
		    this.totalPage = res.data.totalPage;
        this.list = [ ...this.list, ...res.data.list ];
         this.isShowLoading = false
      },
      onScroll(e) {
        const {
          scrollTop,
          clientHeight,
          scrollHeight
        } = e.target;

        if (
          scrollTop + clientHeight == scrollHeight &&
		  this.totalPage > this.pageNumber
        ) {
          this.pageNumber++;
          this.getMessage();
        }
      },
	  async getOpen(msg) {
		  this.currfriend = {
		  	fansAddress: msg.friendAddress,
		  	fansName: msg.receiverName
		  }
		  this.open = true;
          msg.wordsStatus = 1;
          this.$refs.message.init(this.currfriend);
          setTimeout(() => { window.eventBus.$emit("ViewMessage", true) }, 1000);
	  },
	  Open(e) {
		  this.open = e;
          window.eventBus.$emit("ViewMessage", true);
    },
      allReadMessage(){
       this.list.forEach(async v => {
               
              v.wordsStatus =1
            })
    },
    },
    mounted() {
        window.eventBus.$on("allReadMessage", this.allReadMessage);
      this.getMessage();
    }
  };

</script>

<style lang='scss' scoped>
	.overflow-y-auto {
		height: 690px;
	}
  .empty {
					width: 100%;
					height: 500px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
</style>
